<template>
	<div class="order">
		<CCard class="h-100">
			<CCardBody>
				<div class="order__header">
					<h5 class="mb-3">
						{{ getTitle }}
					</h5>
				</div>
				<template v-for="(order, index) in orders">
					<OrderWebItem
						:key="`${order.id}-${index}`"
						:order="order"
						:is-view="isView"
						:original-id="id"
					/>
				</template>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { GET_ORDERS, GET_ORDER, DELETE_ORDER } from "../store/action-types"
import { SET, RESET_ORDER_FILTERS } from "../store/mutation-types"
import { USER_ROLE, ORDER_SORT } from "../../../shared/plugins/constants"
const { mapState, mapActions, mapMutations } = createNamespacedHelpers("orders")
export default {
	name: "OrderWebDetail",
	components: {
		OrderWebItem: () => import("../components/OrderWebItem.vue"),
	},
	props: {
		isUpdate: {
			type: Boolean,
			default: false,
		},
		isView: {
			type: Boolean,
			default: false,
		},
		id: {
			type: String,
			default: null,
		},
	},
	computed: {
		...mapState(["orders"]),
		getTitle() {
			return `${this.$t("OrdersTitle.Order")} ${this.id}`
		},
		currentUser() {
			return this.$store.state.authentication.currentUser
		},
		displayWithRole() {
			if (this.currentUser.role !== USER_ROLE.QA) {
				return true
			}
			return false
		},
	},
	created() {
		this.getOrdersUpdate()
	},
	beforeDestroy() {
		this.RESET_ORDER_FILTERS()
	},
	methods: {
		...mapActions({ GET_ORDERS, GET_ORDER, DELETE_ORDER }),
		...mapMutations({ SET, RESET_ORDER_FILTERS }),
		async getOrdersUpdate() {
			const params = {
				data: {
					originalOrderId: this.id,
					sortBy: ORDER_SORT.CREATED_AT,
				},
				notLoading: false,
			}
			await this.GET_ORDERS(params)
		},
		AddUpdateOrder() {
			this.$router.push({
				path: "/orders/web/create",
				query: { original_order: this.id, update_order: true },
			})
		},
	},
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.order {
	height: 100%;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
	}
}
</style>
